import React from 'react';

const About = () => (
  <div style={{ padding: '20px', color: '#fff', textAlign: 'center' }}>
    <h1>About Me</h1>
    <p>I am a passionate 3D artist and developer creating interactive experiences.</p>
  </div>
);

export default About;
