// src/components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav style={{
      width: '100%',
      backgroundColor: 'rgba(18, 18, 18, 0.8)', // Semi-transparent background
      color: 'white',
      padding: '10px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      top: 0,
      zIndex: 1000,
      backdropFilter: 'blur(8px)', // Glass effect
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.3)', // Subtle shadow
    }}>
      {/* Logo */}
      <div style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
        <Link to="/" style={{
          textDecoration: 'none',
          color: 'white',
        }}>
          Go Game Dev
        </Link>
      </div>

      {/* Links for Desktop */}
      <div style={{
        display: isOpen ? 'none' : 'flex',
        gap: '20px',
      }}>
        <Link to="/" style={linkStyle}>דף הבית</Link>
        <Link to="/courses" style={linkStyle}>הקורסים</Link>
        <Link to="/contact" style={linkStyle}>צור קשר</Link>
      </div>

      {/* Hamburger Menu for Mobile */}
      <div style={{
        cursor: 'pointer',
        fontSize: '1.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }} onClick={toggleMenu}>
        ☰
      </div>

      {/* Dropdown Menu for Mobile */}
      {isOpen && (
        <div style={{
          position: 'absolute',
          top: '60px',
          right: '0',
          backgroundColor: 'rgba(30, 30, 30, 0.9)', // Semi-transparent background
          width: '100%',
          padding: '20px',
          textAlign: 'center',
          boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)',
        }}>
          <Link to="/" style={dropdownLinkStyle} onClick={toggleMenu}>דף הבית</Link>
          <Link to="/courses" style={dropdownLinkStyle} onClick={toggleMenu}>הקורסים</Link>
          <Link to="/contact" style={dropdownLinkStyle} onClick={toggleMenu}>צור קשר</Link>
        </div>
      )}
    </nav>
  );
};

const linkStyle = {
  textDecoration: 'none',
  color: 'white',
  fontSize: '1rem',
  fontWeight: 'bold',
};

const dropdownLinkStyle = {
  textDecoration: 'none',
  color: 'white',
  fontSize: '1rem',
  fontWeight: 'bold',
  display: 'block',
  padding: '10px 0',
};

export default Navbar;
