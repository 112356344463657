import React from 'react';
import { Html } from '@react-three/drei';

const Tooltip = ({ text, position }) => {
  return (
    <Html position={position} style={{ pointerEvents: 'none' }}>
      <div
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          color: 'white',
          padding: '5px 10px',
          borderRadius: '5px',
          fontSize: '0.9rem',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </div>
    </Html>
  );
};

export default Tooltip;
