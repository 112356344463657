import { Sky } from '@react-three/drei';

const EnvironmentOne = () => {
  return (
    <>
      {/* Add a Sky with gradient */}
      <Sky
        distance={450000} // Sky distance
        sunPosition={[100, 20, 100]} // Position of the sun
        inclination={0.49} // Sun elevation angle
        azimuth={0.25} // Sun rotation around Y-axis
        turbidity={10} // Atmosphere turbidity (affects sky gradient)
        rayleigh={2} // Atmosphere density (affects scattering)
        mieCoefficient={0.005} // Affects haze and sun halo
        mieDirectionalG={0.8} // Sun halo appearance
      />
      {/* Ambient Light for overall brightness */}
      <ambientLight intensity={0.4} />
      {/* Directional Light for shadows */}
      <directionalLight
        position={[10, 10, 10]}
        intensity={1.2}
        castShadow
      />
    </>
  );
};

export default EnvironmentOne;
