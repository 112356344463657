import React, { Suspense, useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { OrbitControls, Environment } from '@react-three/drei';
import AnimatedModel from '../models/AnimatedModel';
import BoxModel from '../models/BoxModel';
import ChamberModel from '../models/ChamberModel';
import ComputerModel from '../models/ComputerModel';
import ForestModel from '../models/ForestModel';
import TreeModel from '../models/TreeModel';
import StorageUnitModel from '../models/StorageUnitModel';
import FloorPlatformModel from '../models/FloorPlatformModel';
import TerrModel from '../models/TerrModel';
import HeadsetModel from '../models/HeadsetModel';
import TexturedPlane from './TexturedPlane';
import Floor from './TexturedFloor';
import EnvironmentOne from './SkyEnv';
import * as THREE from 'three';

const Loader = () => (
  <div
    style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      fontSize: '1.5rem',
      textAlign: 'center',
    }}
  >
    Loading 3D Scene...
  </div>
);

// Particle System
const Particles = () => {
  const points = useRef();
  const particleTexture = useLoader(THREE.TextureLoader, '/textures/circle_05.png');
  const particleCount = 1000;
  const particlesPosition = new Float32Array(particleCount * 3);

  for (let i = 0; i < particleCount; i++) {
    particlesPosition[i * 3] = (Math.random() - 0.5) * 20;
    particlesPosition[i * 3 + 1] = (Math.random() - 0.5) * 10;
    particlesPosition[i * 3 + 2] = (Math.random() - 0.5) * 20;
  }

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime();
    if (points.current) {
      points.current.rotation.y = elapsedTime * 0.1;
    }
  });

  return (
    <points ref={points}>
      <bufferGeometry>
        <bufferAttribute
          attach="attributes-position"
          array={particlesPosition}
          count={particleCount}
          itemSize={3}
        />
      </bufferGeometry>
      <shaderMaterial
        attach="material"
        uniforms={{
          uTexture: { value: particleTexture },
          uColor: { value: new THREE.Color('#6ea5ff') },
        }}
        vertexShader={`varying vec2 vUv;
          void main() {
            vUv = uv;
            vec4 modelPosition = modelMatrix * vec4(position, 1.0);
            vec4 viewPosition = viewMatrix * modelPosition;
            vec4 projectionPosition = projectionMatrix * viewPosition;
            gl_Position = projectionPosition;
            gl_PointSize = 40.0 / -viewPosition.z;
          }
        `}
        fragmentShader={`uniform sampler2D uTexture;
          uniform vec3 uColor;
          varying vec2 vUv;

          void main() {
            vec4 textureColor = texture2D(uTexture, gl_PointCoord);
            if (textureColor.a < 0.1) discard;
            gl_FragColor = vec4(uColor, 1.0) * textureColor * 10.0;
          }
        `}
        transparent={true}
        depthWrite={false}
      />
    </points>
  );
};

const ZoomAnimation = () => {
  const cameraStart = useRef(null);

  useFrame(({ camera, clock }) => {
    if (!cameraStart.current) {
      cameraStart.current = camera.position.clone(); // Store the initial camera position
    }
    const elapsedTime = clock.getElapsedTime();
    const progress = Math.min(elapsedTime / 2, 1); // Control zoom duration (2 seconds)
    camera.position.lerpVectors(cameraStart.current, new THREE.Vector3(-0.25, 2.45, 5.91), progress);
    camera.lookAt(0, 2, 0);
  });

  return null;
};

const ThreeDCanvas = ({ onColorChange }) => {
  const mousePosition = useRef({ x: 0, y: 0 });
  const [camera, setCamera] = useState(null);

  const handleMouseMove = (event) => {
    const x = (event.clientX / window.innerWidth) * 2 - 1;
    const y = -(event.clientY / window.innerHeight) * 2 + 1;
    mousePosition.current = { x, y };
  };

  return (
    <div
      style={{ position: 'relative', width: '100vw', height: '100vh' }}
      onMouseMove={handleMouseMove}
    >
      <Suspense fallback={<Loader />}>
        <Canvas
          shadows
          camera={{
            position: [-0.25036791201282194, 2.453572547166232, 5.911266770513],
            fov: 50,
          }}
          onCreated={({ camera }) => setCamera(camera)}
        >
          <Environment files="/environment.hdr" background={false} />
          <ambientLight intensity={0.5} />
          <directionalLight position={[5, 10, 5]} intensity={1.5} castShadow />

          {/* Ground Plane */}
          <TexturedPlane />

          {/* Animated Model */}
          <AnimatedModel
            position={[-0.1, 0.3, 0]}
            tooltipText="תלחצו עליי"
            mousePosition={mousePosition}
            camera={camera}
            onColorChange={onColorChange}
          />

          {/* Multiple Boxes */}
          <BoxModel position={[-4, 1.7, 0]} rotation={[0, 0.5, 0]} />
          <BoxModel position={[-5, 0.5, 0]} rotation={[0, -0.75, 0]} />
          <BoxModel position={[-3, 0.5, 0]} rotation={[0, 0, 0]} />

          <BoxModel position={[13, 0, -10]} rotation={[0, -0.75, 0]} />
          <BoxModel position={[11, 0, -10]} rotation={[0, -0.75, 0]} />
          <BoxModel position={[13, 0, -8]} rotation={[0, -0.75, 0]} />

          <BoxModel position={[13, 1, -10]} rotation={[0, -0.75, 0]} />
          <BoxModel position={[11, 1, -10.5]} rotation={[0, -0.2, 0]} />
          <BoxModel position={[13, 1, -8]} rotation={[0, -0.75, 0]} />
          <BoxModel position={[2.1, 0, -4]} rotation={[0, -0.75, 0]} />
          <BoxModel position={[2.1, 0, 4]} rotation={[0, -0.75, 0]} />
          <BoxModel position={[-1.8, 0, -4]} rotation={[0, -0.75, 0]} />
          <ComputerModel position={[1.5, 0, -4]} rotation={[0, -0.75, 0]} />z

          <HeadsetModel position={[-2.5, 1.7, 0]} rotation={[-0.25, 0.75, 0.5]} scale={[0.5, 0.5, 0.5]} />
          <ChamberModel position={[-2.5, 1.7, 0]} rotation={[-0.25, 0.75, 0.5]} scale={[0.5, 0.5, 0.5]} />
          <ForestModel position={[-2.5, 1.7, 0]} rotation={[-0.25, 0.75, 0.5]} scale={[0.5, 0.5, 0.5]} />
          <FloorPlatformModel />
          <Floor />

          {/* Trees */}
          <TreeModel position={[10, 0, -20]} rotation={[0, 0.5, 0]} scale={[8, 8, 8]} />
          <TreeModel position={[0, 0, -20]} rotation={[0, 0, 0]} scale={[8, 8, 8]} />
          <TreeModel position={[-10, 0, -20]} rotation={[0, -0.5, 0]} scale={[8, 8, 8]} />
          <TreeModel position={[20, 0, -20]} rotation={[0, 0.25, 0]} scale={[6, 6, 6]} />
          <TreeModel position={[17, 0, -20]} rotation={[0, 0.85, 0]} scale={[5, 5, 5]} />
          <TreeModel position={[20, 0, -15]} rotation={[0, 0.85, 0]} scale={[5, 5, 5]} />
          <TreeModel position={[10, 0, -15]} rotation={[0, 0.85, 0]} scale={[5, 5, 5]} />
          <TreeModel position={[-10, 0, -15]} rotation={[0, 0.85, 0]} scale={[5, 5, 5]} />
          <TreeModel position={[-14, 0, -15]} rotation={[0, 0.85, 0]} scale={[5, 5, 5]} />
          <TreeModel position={[-18, 0, -14]} rotation={[0, 0.85, 0]} scale={[5, 5, 5]} />
          {/* <TreeModel position={[-2, 0, -5]} rotation={[0, 0.85, 0]} scale={[2, 2, 2]} /> */}

          {/* Storage Unit Models */}
          <StorageUnitModel position={[0, 0, -15]} />
          <StorageUnitModel position={[3.3, 0, -15]} />
          <StorageUnitModel position={[-3.3, 0, -15]} />
          <StorageUnitModel position={[-6.6, 0, -15]} />
          <StorageUnitModel position={[6.6, 0, -15]} />

          {/* Environment */}
          <EnvironmentOne />

          {/* Particle System */}
          <Particles />

          {/* Zoom Animation */}
          <ZoomAnimation />

          {/* Orbit Controls */}
          <OrbitControls target={[-0.25, 2.45, 4.5]} />
        </Canvas>
      </Suspense>
    </div>
  );
};

export default ThreeDCanvas;
