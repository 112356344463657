import React, { useState } from 'react';
import ThreeDCanvas from '../components/ThreeDCanvas';

const Home = () => {
  const [buttonColor, setButtonColor] = useState('#3474eb'); // Default button color

  const handleColorChange = () => {
    setButtonColor('#14243d'); // Change to green
    setTimeout(() => {
      setButtonColor('#3474eb'); // Reset to default after 2 seconds
    }, 3700); // Reset after 2 seconds
  };

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh' }}>
      <ThreeDCanvas onColorChange={handleColorChange} />
      <div
        style={{
          position: 'absolute',
          top: '10%',
          left: '10%',
          color: 'white',
          textAlign: 'right',
          direction: 'rtl',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          padding: '20px',
          borderRadius: '10px',
          maxWidth: '400px',
        }}
      >
        <h1 style={{ marginBottom: '30px', fontSize: '1.7rem' }}>ברוכים הבאים ל-Go Game Dev</h1>
        <p style={{ marginBottom: '20px', fontSize: '1.0rem', lineHeight: '1.6' }}>
          הצטרפו אלינו ותלמדו פיתוח משחקים בתלת-ממד ובדו-ממד, עם הדרכות מעשיות וחוויות אינטראקטיביות!
        </p>
        <button
          style={{
            padding: '10px 20px',
            backgroundColor: buttonColor,
            border: 'none',
            color: 'white',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '1rem',
            transition: 'background-color 0.3s ease', // Smooth transition
          }}
          onClick={() => window.location.href = '/courses'}
        >
          חקרו את הקורסים שלנו
        </button>
      </div>
    </div>
  );
};

export default Home;
