// src/pages/Contact.js
import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls, Sphere } from '@react-three/drei';
import * as THREE from 'three';

const Contact = () => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #121212, #1e1e1e)',
        color: 'white',
        padding: '40px 20px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
          marginBottom: '30px',
          fontSize: '2.5rem',
          color: '#ffd700',
          textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)',
        }}
      >
        צור קשר
      </h1>
      <p
        style={{
          marginBottom: '20px',
          fontSize: '1rem',
          textAlign: 'center',
          lineHeight: '1.6',
          maxWidth: '600px',
          direction: 'rtl', // Ensure proper Hebrew alignment
        }}
      >
        נשמח לשמוע מכם! אם יש לכם שאלות, בקשות או הצעות, אל תהססו לפנות אלינו.
      </p>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          maxWidth: '500px',
          width: '100%',
          padding: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          borderRadius: '10px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
          direction: 'rtl',
        }}
      >
        <input
          type="text"
          placeholder="שם מלא"
          style={inputStyle}
          aria-label="שם מלא"
        />
        <input
          type="email"
          placeholder="כתובת אימייל"
          style={inputStyle}
          aria-label="כתובת אימייל"
        />
        <textarea
          placeholder="הודעה"
          style={{ ...inputStyle, height: '100px', resize: 'none' }}
          aria-label="הודעה"
        ></textarea>
        <button
          type="submit"
          style={{
            padding: '10px 20px',
            backgroundColor: '#3474eb',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            fontSize: '1rem',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.backgroundColor = '#285bb5')
          }
          onMouseLeave={(e) =>
            (e.currentTarget.style.backgroundColor = '#3474eb')
          }
        >
          שלח הודעה
        </button>
      </form>
      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          height: '400px',
          marginTop: '40px',
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.4)',
        }}
      >
        <GlobeCanvas />
      </div>
    </div>
  );
};

// Globe Component
const Globe = () => {
  const ref = useRef();

  // Automatic rotation
  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.y += 0.001; // Rotate slowly
    }
  });

  const texture = new THREE.TextureLoader().load('/textures/earth.jpg'); // Replace with your texture path

  return (
    <mesh ref={ref}>
      <sphereGeometry args={[2, 64, 64]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
};

const GlobeCanvas = () => (
  <Canvas
    style={{ width: '100%', height: '100%' }}
    camera={{ position: [5, 3, 0], fov: 45 }}
  >
    {/* Lighting */}
    <ambientLight intensity={1.5} />
    <directionalLight position={[10, 10, 10]} intensity={1} />
    <directionalLight position={[-10, -10, -10]} intensity={1.5} />

    {/* Globe */}
    <Globe />

    {/* Orbit Controls */}
    <OrbitControls enableZoom={true} />
  </Canvas>
);

// Input Style
const inputStyle = {
  width: '100%',
  padding: '10px',
  borderRadius: '5px',
  border: '1px solid #444',
  backgroundColor: '#1e1e1e',
  color: 'white',
  fontSize: '1rem',
  textAlign: 'right', // Align for Hebrew
};

export default Contact;
