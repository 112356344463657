// src/pages/Courses.js
import React from 'react';
import { Link } from 'react-router-dom';

const courses = [
  {
    id: 1,
    title: 'פיתוח משחקים דו-ממדיים',
    description: 'למדו ליצור משחקי דו-ממד עם כלי עיצוב ואנימציה בסיסיים.',
    videoUrl: 'https://www.youtube.com/embed/your-video-id-1',
    price: '₪500',
    syllabusLink: '/syllabus-2d',
  },
  {
    id: 2,
    title: 'פיתוח משחקים תלת-ממדיים',
    description: 'למדו ליצור עולמות תלת-ממדיים ולשלב בהם תנועת דמויות.',
    videoUrl: 'https://www.youtube.com/embed/your-video-id-2',
    price: '₪750',
    syllabusLink: '/syllabus-3d',
  },
  {
    id: 3,
    title: 'פיתוח משחקים מבוססי מנוע פיזיקלי',
    description: 'למדו לשלב מנועי פיזיקה וליצור משחקים אינטראקטיביים.',
    videoUrl: 'https://www.youtube.com/embed/your-video-id-3',
    price: '₪1000',
    syllabusLink: '/syllabus-physics',
  },
];

const Courses = () => {
  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        background: 'linear-gradient(to bottom, #121212, #1e1e1e)',
        color: 'white',
        padding: '40px 20px',
        boxSizing: 'border-box',
      }}
    >
      <h1
        style={{
          textAlign: 'center',
          marginBottom: '50px',
          fontSize: '2.5rem',
          color: '#ffd700',
          textShadow: '0 2px 5px rgba(0, 0, 0, 0.7)',
        }}
      >
        הקורסים שלנו
      </h1>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '30px',
          alignItems: 'start',
          maxWidth: '1200px',
          margin: '0 auto',
        }}
      >
        {courses.map((course) => (
          <div
            key={course.id}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              borderRadius: '10px',
              padding: '20px',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
              textAlign: 'right',
              direction: 'rtl',
            }}
          >
            <h2
              style={{
                marginBottom: '15px',
                fontSize: '1.5rem',
                color: '#ffd700',
                textShadow: '0px 2px 4px rgba(0, 0, 0, 0.7)',
              }}
            >
              {course.title}
            </h2>
            <p
              style={{
                marginBottom: '20px',
                fontSize: '1rem',
                lineHeight: '1.5',
                color: '#d1d1d1',
              }}
            >
              {course.description}
            </p>
            <div
              style={{
                marginBottom: '15px',
                fontSize: '1.2rem',
                fontWeight: 'bold',
                color: '#ffd700',
              }}
            >
              מחיר: {course.price}
            </div>
            <a
              href={course.syllabusLink}
              style={{
                display: 'inline-block',
                padding: '10px 20px',
                backgroundColor: '#3474eb',
                color: 'white',
                textDecoration: 'none',
                borderRadius: '5px',
                fontSize: '1rem',
                transition: 'background-color 0.3s ease',
              }}
              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#285bb5')}
              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#3474eb')}
              target="_blank"
              rel="noopener noreferrer"
            >
              עיין בסילבוס
            </a>
            <div
              style={{
                width: '100%',
                height: '200px',
                marginTop: '20px',
                overflow: 'hidden',
                borderRadius: '10px',
                boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.4)',
              }}
            >
              <iframe
                width="100%"
                height="100%"
                src={course.videoUrl}
                title={course.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: 'none' }}
              ></iframe>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Courses;
