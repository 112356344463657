import React, { useRef } from 'react';
import { useGLTF } from '@react-three/drei';

const BoxModel = ({ position = [0, 0, 0], rotation = [0, 0, 0], scale = [1, 1, 1] }) => {
  const { scene } = useGLTF('/Box.glb'); // Load the GLB model
  const clonedScene = scene.clone(); // Clone the scene for independent instances
  const ref = useRef();

  // Optional: Add rotation animation
  // useFrame(() => {
  //   if (ref.current) {
  //     ref.current.rotation.y += 0.01; // Rotate the model
  //   }
  // });

  return <primitive ref={ref} object={clonedScene} position={position} rotation={rotation} scale={scale} />;
};

export default BoxModel;
