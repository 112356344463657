import React, { useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import * as THREE from 'three';

const NaturalGround = () => {
  // Load textures
  const aoMap = useLoader(THREE.TextureLoader, '/textures/ao.jpg');
  const colorMap = useLoader(THREE.TextureLoader, '/textures/uploaded.jpg');
  const displacementMap = useLoader(THREE.TextureLoader, '/textures/displacement.jpg');
  const normalMap = useLoader(THREE.TextureLoader, '/textures/normal.jpg');
  const roughnessMap = useLoader(THREE.TextureLoader, '/textures/roughness.jpg');

  // Adjust texture settings
  useEffect(() => {
    [colorMap, aoMap, displacementMap, normalMap, roughnessMap].forEach((texture) => {
      texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
      texture.repeat.set(40, 40); // Reduce tiling for larger patches
      texture.minFilter = THREE.LinearMipMapLinearFilter;
      texture.magFilter = THREE.LinearFilter;
      texture.anisotropy = 16;
    });
  }, [colorMap, aoMap, displacementMap, normalMap, roughnessMap]);

  return (
    <mesh receiveShadow rotation={[-Math.PI / 2, 0, 0]} position={[1.3, -2, -14]}>
      {/* Plane Geometry */}
      <planeGeometry args={[200, 200, 60, 60]} />
      {/* MeshStandardMaterial */}
      <meshStandardMaterial
        map={colorMap} // Color texture
        aoMap={aoMap} // Ambient Occlusion
        displacementMap={displacementMap} // Displacement map
        displacementScale={5.5} // Increased displacement for terrain variation
        normalMap={normalMap} // Normal map
        roughnessMap={roughnessMap} // Roughness map
        roughness={0.6} // Slightly rough surface
      />
    </mesh>
  );
};

export default NaturalGround;
