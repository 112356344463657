import React, { useRef, useEffect, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import * as THREE from 'three';
import Tooltip from '../components/ToolTip.js';

const AnimatedModel = ({
  position = [-0.1, 0.3, 0],
  rotation = [0, 0, 0],
  scale = [1.5, 1.5, 1.5],
  tooltipText = '',
  mousePosition,
  camera,
  onColorChange,
}) => {
  const { scene, animations } = useGLTF('/Human.gltf');
  const { actions, mixer } = useAnimations(animations, scene);
  const headRef = useRef();
  const [hovered, setHovered] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const tempVector = new THREE.Vector3();
  const headWorldPosition = new THREE.Vector3();
  const targetPosition = new THREE.Vector3();
  const [clickAction, setClickAction] = useState(null);

  useEffect(() => {
    if (animations.length > 0) {
      const headBoneName = 'CC_Base_Head';
      const originalClip = animations[2];

      // Filter out tracks affecting the head bone
      const filteredTracks = originalClip.tracks.filter(
        (track) => !track.name.includes(headBoneName)
      );

      // Create a new clip without head bone tracks
      const newClip = new THREE.AnimationClip(
        originalClip.name,
        originalClip.duration,
        filteredTracks
      );

      // Create the click action from the new clip
      const createdClickAction = mixer.clipAction(newClip, scene);
      setClickAction(createdClickAction);
      createdClickAction.play();

      // Find the head bone for manual control
      scene.traverse((child) => {
        if (child.isBone && child.name === headBoneName) {
          headRef.current = child;
        }
      });
    }
  }, [animations, mixer, scene]);

  useEffect(() => {
    const currentRotation = new THREE.Vector3(); // Store the current rotation
    const targetRotation = new THREE.Vector3(); // Store the target rotation

    const handleLookAtMouse = () => {
      if (headRef.current && mousePosition?.current && camera) {
        const { x, y } = mousePosition.current;

        // Convert normalized mouse position to 3D space
        tempVector.set(x + 0.35, y, 0.5).unproject(camera);

        headRef.current.getWorldPosition(headWorldPosition);
        targetPosition.copy(tempVector).sub(headWorldPosition).normalize();

        // Calculate target rotations
        const movementScale = 20.5;
        targetRotation.x = THREE.MathUtils.clamp(-targetPosition.y * movementScale, -0.5, 0.5);
        targetRotation.y = THREE.MathUtils.clamp(targetPosition.x * movementScale, -0.5, 0.5);

        // Smoothly interpolate current rotation toward target rotation
        currentRotation.x = THREE.MathUtils.lerp(currentRotation.x, targetRotation.x, 0.1);
        currentRotation.y = THREE.MathUtils.lerp(currentRotation.y, targetRotation.y, 0.1);

        // Apply smoothed rotation
        headRef.current.rotation.x = currentRotation.x;
        headRef.current.rotation.y = currentRotation.y;

        headRef.current.updateMatrixWorld(true);
      }
    };

    const interval = setInterval(handleLookAtMouse, 16); // Smooth updates
    return () => clearInterval(interval);
  }, [mousePosition, camera]);

  const handleClick = () => {
    if (!clickAction) return;
    if (isAnimating) return;

    setIsAnimating(true);

    if (onColorChange) onColorChange();

    const touchAction = actions['Touch Thing']; // Use original animation
    const greetAction = actions['Greet']; // Use original animation

    if (touchAction && greetAction) {
      // Cross-fade from clickAction to touchAction
      clickAction.crossFadeTo(touchAction.reset().fadeIn(0.5), 0.5).play();
      touchAction.loop = THREE.LoopOnce;
      touchAction.clampWhenFinished = true;

      mixer.addEventListener('finished', (e) => {
        if (e.action === touchAction) {
          // Cross-fade from touchAction to greetAction
          touchAction.fadeOut(0.5);
          greetAction.reset().fadeIn(0.5).play();
          greetAction.loop = THREE.LoopOnce;
          greetAction.clampWhenFinished = true;

          mixer.addEventListener('finished', (e) => {
            if (e.action === greetAction) {
              greetAction.fadeOut(0.5); // End greet animation
              clickAction.reset().fadeIn(0.5).play(); // Resume the filtered animation
              setIsAnimating(false);
            }
          });
        }
      });
    }
  };

  return (
    <group>
      <primitive
        object={scene}
        position={position}
        rotation={rotation}
        scale={scale}
        onClick={handleClick}
        onPointerOver={() => setHovered(true)}
        onPointerOut={() => setHovered(false)}
      />
      {hovered && <Tooltip text={tooltipText} position={[position[0] + 0.5, position[1] + 2.2, position[2]]} />}
    </group>
  );
};

export default AnimatedModel;
